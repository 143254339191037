




















































































































































  .el-aside {
      height: calc(100vh - 100px);// vh就是当前屏幕可见高度的1%   只用设置左侧 aside 高度
  }
  .oneMenu{
      line-height:35px;
      text-align:left;
      padding-left:35px;
      font-size:14px;
      color:white;
      background-color: #409eff;
  }
  .oneMenu:hover{
      cursor: pointer;
      color:#f4f6f8;
  }
  .twoMenu{
      display:flex;
      flex-direction:column;
      line-height:40px;
      background:white;
      padding-left:35px;

  }
  .twoMenu:hover{
      cursor: pointer;
      color:#409EFF;
  }
  .top{
      padding: 10px;
      background: rgba(0, 153, 229);
      color: #fff;
      text-align: center;
      font-size:10px;
      border-radius: 2px;
  }  
  .el-header  {
      background-color: #fff;
      color: #409EFF;
      text-align: center;
      line-height: 60px;
      width:100%;
  }
.el-main {
  background-color: #fff;
  color: rgb(207, 206, 206);
  text-align: center;
  height:100%;
}

.footer-css {
      width: 100%;
      position: relative;
      bottom: 0 ;
      background:#f8f8f9;
      height:360px !important;
      vertical-align: center;
      text-align: center;
      color:#409EFF;
      font-weight: 600;
      font-size:12px;
  } 

  .active { 
      color:#D200D2; 
  } 
  .wraper{
      width:1250px;
      margin:1px auto;
  }
  .bread{
      cursor: pointer;
  }
  .bread:hover{
      color:#409eff;
  }
